.user-management {
    width: 100%;
    height: 100%;
    // padding: 16px;
    
    .page-header {
      padding: 24px;
      margin-bottom: 10px;
    }
    .user_roles_select {
     width: fit-content;
      padding-left: 24px;
      margin-top: 0px;
    //   width: max-content ;
    //   margin-bottom: 0px;
     }
     
     @media screen and (max-width: 768px) {
       .page-header{
        padding-bottom: 4px;
        .button_container{
            margin-bottom: 8px;
        }
       }
       .user_roles_select{
        row-gap: 8px;
        margin-bottom: 0px;
        .user_select_box{
            .ant-form-item-label{
                padding-bottom: 0px;
            }
        }
        .select_sd{
            row-gap: 8px;
        }
       }
      }
    
  }

  .table-pagination{
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        display: none;
    }
    .ant-pagination-prev, .ant-pagination-next {
    line-height: 32px !important;
    }
    .ant-pagination-item-link{
        .anticon{
            color: #262626 !important;
            vertical-align: 0.05em !important;
        }
    }

  .ant-pagination-item-link:disabled{
        background-color:  #D9D9D9 !important;
        .anticon{
        color: #BFBFBF !important;
        }
    }
  }
  .btns {
    .ant-btn{
        height: 40px;
    }
    .ant-btn-primary{
      background-color: black;
      border-color: black;
    }
    .ant-btn-primary:hover{
      background-color: black;
    }
  }
  .service_policy{
    a:hover{
      color: unset !important;
    }
    a:active{
      color: unset !important;
    }
  }