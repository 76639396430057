body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (max-width: 768px) {
    background: #f0f2f5 !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(98vw);
  height: calc(100vh);
  z-index: -1;
}

iframe {
  z-index: -10 !important;
}

.login_submit {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-btn-loading-icon {
    display: flex;
  }
}

.side_footer p {
  font-size: 10px !important;
}

.side_bar__footer {
  p {
    font-size: 10px !important;
  }

  a {
    font-size: 10px !important;
  }
}

.side_nav_footer {
  p {
    font-size: 10px !important;
  }

  li {
    font-size: 10px !important;
  }
}

// .login__logo {
//   padding-top: 24px;
//   // padding-bottom: 24px;
// }

.login__content {
  height: 100vh;
  .user_select_box {
    .ant-form-item-row {
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: left;
    }
  }

  // @media (max-width: 768px) {
  //   overflow: hidden;
  // }
}

// mobile footer
.mobile_footer_dropdown {
  .ant-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0 !important;
  }
}

.mobile_header_dropdown {
  padding-right: 20px;
}

// change password
.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.login_cta {
  display: flex;
  justify-content: center;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    display: flex;
    align-items: center;
  }
}

.passwordInput {
  width: 24px;
  right: 2%;
  top: calc(50% - 12px);
  cursor: pointer;

  @media (max-width: 768px) {
    top: calc(50% - 12px);
  }
}
h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.ant-form-item-label > label::after {
  content: '' !important;
}

.site-layout {
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
}

.ant-btn-primary {
  background-color: black;
}

.new-user-form {
  .ant-row {
    &.ant-form-item-row {
      display: block;
      width: 100%;
    }
  }
  .innerForm {
    .ant-row {
      &.ant-form-item-row {
        display: flex;
        width: 100%;
        gap: 4px;

        button {
          background-color: #00000040;
          &.ant-switch-checked {
            background-color: #1890ff;
          }
        }
      }
    }
  }
}
.modules-tabs {
  .ant-tabs-nav {
    padding: 12px;
    background: white;
    margin: 0;
    @media only screen and (max-width: 600px) {
      padding: 0px 0px 0px 20px;
    }

    @media screen and (min-width: 768px) {
      padding: 0 24px 0;
      background: white;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        @media only screen and (max-width: 768px) {
          margin-right: 30px;
          margin-left: 0;
        }
      }
    }
  }
  .ant-tabs-content-holder {
    padding: 24px;
    padding-top: 8px;
    background-color: #f5f5f5;
    @media only screen and (max-width: 600px) {
      padding: 16px 10px;
    }

    .ant-tabs-content-top {
      background: #f5f5f5;
      width: 100%;
      // padding: 16px;
      border-radius: 8px;
      padding-top: 0px;
      /* box-shadow: -1px 1px 11px 2px rgba(0, 0, 0, 10%);
      -webkit-box-shadow: -1px 1px 11px 2px rgba(0, 0, 0, 10%);
      -moz-box-shadow: -1px 1px 11px 2px rgba(0, 0, 0, 10%); */
      @media screen and (max-width: 768px) {
        padding: 0px 0px 30px 10px;
      }

      @media screen and (min-width: 768px) {
        width: 100%;
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #7e62d1;
  }
  .ant-tabs-tab:hover {
    color: #7e62d1;
  }

  .ant-tabs-ink-bar {
    background: #7e62d1;
    pointer-events: none;
  }
}
.modules-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7e62d1;
}

@media (prefers-color-scheme: dark) {
  .custom_layout {
    background: #000 url('./assets/xstaklogoWithoutName2.svg') no-repeat right -650px bottom / 1500px;
  }
}
@media (prefers-color-scheme: light) {
  .custom_layout {
    background: #9881da url('./assets/xstaklogoWithoutName.svg') no-repeat right -650px bottom / 1500px;
    .service_policy {
      color: #fafafa;
    }
  }
}
.login_btn:hover .anticon {
  color: #5090c7 !important;
}
.login_btn:focus .anticon {
  color: #5090c7 !important;
}
.input_icon {
  color: #8c8c8c !important;
}
.ant-tabs-content-holder {
  height: 72vh;
  overflow: auto;
}
.showCollapse {
  border: 1px red;
}
.side_bar_wrapper {
  .ant-layout-sider.hide-side-bar {
    height: auto !important;
  }
  .ant-menu {
    .ant-menu-item:hover {
      background-color: #7e62d1;
      border-radius: 3px !important;
      .anticon {
        color: white !important;
      }
    }
    .ant-menu-item:last-child {
      border-radius: 3px !important;
    }
  }
}

.main-page-one {
  .module-opt {
    display: none;
  }
}
.header_logout_btn {
  .ant-dropdown-menu-title-content {
    text-align: center !important;
    padding: 10px !important;
  }
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding-bottom: 0px !important;
  }
}
@media (max-width: 768px) {
  body {
    background-color: #f0f2f5;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
.mob-logout-btn {
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      padding: 8px !important;
    }
  }
}
.side_bar_wrapper .main-page {
  overflow: hidden !important;
  height: 100vh !important;
}
