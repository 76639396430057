.__main {
  .welcome_heading {
    font-size: 28px;
    font-weight: 800;
    @media only screen and (max-width: 576px) {
      padding: 24px 16px;
    }
  }
  .__tabs {
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab-active {
              font-weight: 700 !important;
            }
            .ant-tabs-tab {
              padding-top: 0px;
              padding-bottom: 13px;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
.dashboard__tiles,
.product-dashboard__tiles {
  .explore_channel {
    margin-top: auto;
    .product_tile_image {
      height: 16px;
      margin-bottom: 10px;
      @media only screen and (max-width: 576px) {
        height: 12.8px;
        margin-bottom: 5.6px;
      }
    }
    .xap_signup_button {
      font-size: 10px;
      font-weight: 700;
      height: 12px;
    }

    .demo_badge_div {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #382376;
      color: white;
      border-top-right-radius: 8px;
      border-top: 18px solid transparent;
      border-left: 20px solid white;
      border-bottom: 18px solid transparent;
      height: 29px !important;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 576px) {
        border-top: 14px solid transparent;
        border-left: 20px solid white;
        border-bottom: 14px solid transparent;
      }

      .demo_badge {
        padding: 0px 9px 0px 9px;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        @media only screen and (max-width: 576px) {
          font-size: 8px;
        }
      }
    }
  }
  .arrow-icons {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.dashboard__text {
  .h3 {
    padding-top: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #7e62d1;
    @media screen and (max-width: 576px) {
      padding-top: 10px;
      font-weight: 900;
      font-size: 22px;
    }
  }
}

.dashboard_wrapper {
  @media only screen and (max-width: 600px) {
    // height: calc(100vh - 130px);
    // overflow: auto;
    // padding-top: 20px;
    // padding-bottom: 40px;
  }
}

.dashboard,
.product-dashboard {
  --bg-opacity: 1;
  display: grid;
  width: 100%;
  gap: 12px;
  // padding-bottom: 16px;

  @media only screen and (max-width: 767px) {
    height: auto;
    padding-top: 0px;
  }

  &__tilesContainer {
    display: grid;
    gap: 1rem;
  }
  .tilesContainer__one {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: auto;
  }
  .tilesContainer__two {
    display: block;
    width: 100%;
    @media only screen and (max-width: 576px) {
      display: grid;
      padding-right: 11px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 2fr;
      column-gap: 10px;
      row-gap: 12.55px;
    }

    @media only screen and (min-width: 768px) {
      display: flex;
      gap: 2.6%;
      width: auto;
      flex-wrap: wrap;
      row-gap: 20px;
    }
  }
  // @media only screen and (max-width: 600px) {
  //   .col-1 {
  //     grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  //   }
  // }

  &__tiles {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    align-items: center;
    // gap: 20px;
    width: 100%;

    @media only screen and (min-width: 768px) {
      box-shadow: -1px 1px 11px 2px rgba(0, 0, 0, 10%);
      -webkit-box-shadow: -1px 1px 11px 2px rgba(0, 0, 0, 10%);
      -moz-box-shadow: -1px 1px 11px 2px rgba(0, 0, 0, 10%);
      border-radius: 8px;
      flex-basis: 40%;
      //  height: 164px;
      background-color: rgb(255 255 255 / var(--bg-opacity));
      padding: 18px;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 31.5%;
      max-width: 31.5%;
      align-items: flex-start;
      position: relative;
    }

    svg {
      display: block;
      width: 40px;
      height: 40px;
      /* @media only screen and (max-width: 576px) {
        width: 26.19px;
        height: 29.45px;
      } */
    }

    .h6 {
      font-size: 18px;
      font-weight: 600;
      padding-top: 12px;
      padding-bottom: 8px;
      line-height: 24px;
      text-align: left;
      @media only screen and (max-width: 1300px) {
        font-size: 16px;
      }
    }
    .product_type {
      color: #7e62d1;
      font-size: 16px;
      text-transform: capitalize;
    }

    @media only screen and (max-width: 576px) {
      background-color: white;
      flex-direction: column;
      padding: 8px;
      width: 100%;
      align-items: baseline;
      border-radius: 6.54px;
      position: relative;
      // height: 121.45px;
      .h6 {
        font-size: 14px !important;
        line-height: 19px !important;
        padding-top: 4px !important;
        padding-bottom: 5.6px;
      }
    }
  }
  .product-dashboard__tiles:hover {
    background-color: #000000;
    background-image: url('../../../assets/tile-background.png'), url('../../../assets/arrow.svg');
    background-position: right 2px, top 30px right 15px;
    background-repeat: no-repeat;
    background-size: 115px 157px, auto;
    @media only screen and (max-width: 576px) {
      background-position: right 2px, top 15px right 15px;
    }
    .arrow_image {
      display: none;
    }
    .xap_signup_button {
      color: #fff;
    }
    .demo_badge_div {
      border-left: 25px solid black;
    }

    .h6 {
      color: white;
      font-size: 16px;
      // padding-top: 8px;
      font-weight: 400;
      text-align: left;
      width: 90%;
      line-height: 24px;
    }
  }
  .dashboard__tiles:hover {
    background-color: #000000;
    background-image: url('../../../assets/tile-background.png');
    background-position: right 2px, top 30px right 15px;
    background-repeat: no-repeat;
    background-size: 115px 157px, auto;
    @media only screen and (max-width: 576px) {
      background-position: right 2px, top 15px right 15px;
    }
    .arrow_image {
      display: none;
    }
    .xap_signup_button {
      color: #fff;
    }
    .demo_badge_div {
      border-left: 25px solid black;
    }

    .h6 {
      color: white;
      font-size: 16px;
      // padding-top: 8px;
      font-weight: 400;
      text-align: left;
      width: 90%;
      line-height: 24px;
    }
  }
}

.product-dashboard {
  &__tiles {
    @media only screen and (max-width: 600px) {
      &:last-child {
        margin-bottom: auto;
      }
    }
  }
  &__tiles:active {
    background: #000000;
    border-radius: 10px;

    svg {
      color: white !important;
    }

    .h6 {
      color: white;
      padding-top: 12px;
      font-size: 16px;
      text-align: left;
      width: 90%;
      line-height: 20px;
      font-weight: 600;
    }
  }
}

.h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}
.h3 {
  font-size: 1.17em;
  // margin: 0.83em 0;
}
.h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
}
.h6 {
  font-size: 0.75em;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bolder;
}

.tile_heading {
  font-size: 1.17em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bolder;
  text-align: center;
  padding-top: 40px;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  // border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
}

.test_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 20px;
}

.test_item {
  background-color: black;
  color: white;
  height: 150px;
  flex-basis: 40%;
}

.data-platform-img {
  width: 30px;
  height: 30px;
}
