.table {
  display: block !important;
  width: 100%;
  
}
.ant-table-wrapper{
  overflow: auto;
}
.ant-table {
  width: 100%;
  display: block !important;
  overflow: auto;

  table {
    min-width: 1000px;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    line-height: 0 !important;
  }
  @media screen and (max-width: 768px) {
    margin-top: 24px !important;
  }
}

.table .ant-pagination-total-text {
  margin-right: auto;
  color: #8c8c8c;
}

.user_roles_select {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .user_select_box {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-row {
      @media screen and (max-width: 768px) {
        gap: 20px;
      }
    }
  }

  .select_sd {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.user-table{
  padding-left: 24px ;
  padding-right: 24px;
}