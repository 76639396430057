@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(98vw);
  height: calc(100vh);
  z-index: -1;
}

iframe {
  z-index: -10 !important;
}

.mt_24 {
  margin-top: 34px !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 24px;
}