.filter_button {
  padding: 8px 16px !important;
  width: 95px;
  height: 40px !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  border-radius: 2px !important;
}

// .filter_reset {
//   position: absolute;
//   left: 40%;
//   bottom: 10%;
// }

.filter_container {
  height: 60vh;
  overflow: auto;
  padding: 0 4px;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 0px !important;
  }

  > * {
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8c8c8c;
    border-radius: 10px;
  }
}
