.select-field {
  width: 100%;
  min-width: 100%;
  .ant-select-selection-placeholder {
    font-weight: normal;
    @media screen and (max-width: 768px) {
      line-height: 30px !important;
    }
  }
}

.ant-select-selector {
  @media screen and (max-width: 768px) {
    height: 32px !important;
  }
}
.ant-select-selection-search-input {
  @media screen and (max-width: 768px) {
    height: 32px !important;
  }
}

input {
  width: 100px;
}
