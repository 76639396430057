.top_filters_container {
  display: flex;
  margin: 2px 0px 32px 0px;
  > * {
    margin-right: 10px;
    @media screen and (max-width: 768px) {
      margin-right: 0px;
    }
  }
  .search-bar {
    width: 320px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 24px;
    gap: 16px;
    margin-top: 24px;
  }
}
