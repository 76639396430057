.financing-main {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  height: 95vh;
  overflow-y: auto;
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0 !important;
  }

  .ant-space-vertical.financing-header-space .ant-space-item:first-child {
    order: 1;
  }

  .financing-header {
    display: flex;
    align-items: flex-start;
    background: linear-gradient(90deg, #ffffff 20%, #bfb1e8 80%);
    position: relative;

    .header-space-title {
      padding-left: 20px;
      padding-bottom: 20px;

      &-xl,
      &-xxl {
        padding-left: 80px !important;
      }
      &-xs,
      &-sm,
      &-md,
      &-lg {
        padding-right: 20px;
      }
    }
    .header-space-text {
      font-size: 36px;
      color: #4a4e69;
      text-align: left;

      &-xs,
      &-sm,
      &-md {
        font-size: 36px !important;
      }
    }
    .ant-btn-primary.financing-button {
      background-color: #7e62d1 !important;
      border: none;
      border-radius: 7px;
      color: #fff;
      font-size: 16px;
      height: 44px;
      &:hover,
      &:focus,
      &:active {
        background-color: #7e62d1 !important;
        border: none !important;
      }
    }

    .financing-bank-image {
      width: 400px !important;
      height: 300px !important;
      padding-right: 12px !important;
      &-xs,
      &-sm {
        width: 150px !important;
        height: 150px !important;
      }
      &-md,
      &-lg {
        width: 200px !important;
        height: 150px !important;
      }
    }
  }
  .financing-solution {
    padding: 80px 0;
    background: #fff;
    &-header {
      text-align: center;
      padding-bottom: 4vw;
      font-size: clamp(16px, 2vw, 32px);
      line-height: 1.6;
    }
    &-row {
      padding: 0 20px;
      &-xl,
      &-xxl {
        padding: 0 80px;
      }
    }
    &-col {
      padding: 10px 20px;
      border-right: 1px solid #ccc;
      &-xs,
      &-sm,
      &-md {
        border-bottom: 1px solid #ccc;
      }
      &-xs,
      &-sm,
      &-md,
      &-4,
      &-lg-2,
      &-xl-2 {
        border-right: none !important;
      }
    }
    &-title {
      font-size: 20px !important;
    }
  }
  .financing-process-flow {
    position: relative;
    height: 544px;
    background: linear-gradient(90deg, #ffffff, #bfb1e8, #ffffff);

    .ant-typography strong {
      font-size: 16px !important;
      line-height: 19.36px;
    }
    .center-card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 42.623%;
      height: 22.175%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10em / 10em;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      text-align: center;
    }

    .flow-container {
      position: relative;
      width: 68%;
      height: 77.274%;
      &::before {
        content: '';
        position: absolute;
        width: 76.768%;
        height: 84.824%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('./Dotted.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .step {
      position: absolute;
      width: 22.185%;
      min-height: 5.177%;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: start;
      padding: 9px;
      transition: transform 0.3s;
    }

    .step-1 {
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .step-2 {
      top: 34%;
      left: 85%;
      transform: translate(-50%, -50%);
    }
    .step-3 {
      top: 65%;
      left: 85%;
      transform: translate(-50%, -50%);
    }
    .step-4 {
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .step-5 {
      top: 65%;
      left: 15%;
      transform: translate(-50%, -50%);
    }
    .step-6 {
      top: 34%;
      left: 15%;
      transform: translate(-50%, -50%);
    }
    &-image {
      width: 56px;
      height: 56px;
      &-xs,
      &-sm {
        width: 40px !important;
        height: 40px !important;
      }
      &-md {
        width: 45px !important;
        height: 45px !important;
      }
      &-lg {
        width: 50px !important;
        height: 50px !important;
      }
    }
    @media (max-width: 1200px) {
      .flow-container {
        width: 90%;
        height: 75%;
      }
      .center-card {
        width: 43.623%;
        height: 19.208%;
      }
      .step {
        width: 24%;
        min-height: 12%;
      }
    }

    @media (max-width: 992px) {
      .flow-container {
        width: 90%;
        height: 75%;
      }
      .center-card {
        width: 38.623%;
        height: 18.208%;
      }
      .step {
        width: 32%;
        min-height: 10%;
      }
    }

    @media (max-width: 768px) {
      .flow-container {
        width: 90%;
        height: 75%;
      }
      .center-card {
        width: 37.623%;
        height: 20.208%;
      }
      .step {
        width: 25%;
        min-height: 15%;
      }
    }

    @media (max-width: 576px) {
      .flow-container {
        width: 95%;
        height: 75%;
      }
      .center-card {
        width: 43.623%;
        height: 18.208%;
      }
      .step {
        width: 28%;
        min-height: 28%;
        align-items: center;
        text-align: center;
        padding: 4px;
      }
      .step-2,
      .step-6 {
        top: 32%;
      }
      .step-3,
      .step-5 {
        top: 70%;
      }
    }

    @media (max-width: 360px) {
      .flow-container {
        width: 85%;
        height: 70%;
      }
      .step {
        width: 26%;
        min-height: 28%;
        align-items: center;
        text-align: center;
        padding: 4px;
      }
      .ant-typography strong {
        font-size: 14px !important;
        line-height: 19.36px;
      }
      .step-2,
      .step-6 {
        top: 32%;
      }
      .step-3,
      .step-5 {
        top: 70%;
      }
    }
  }

  .financing-license {
    &-space {
      margin: 80px 0px;
    }
    &-card {
      width: 360px;
      text-align: center;
      padding: 0px 20px;
      border-radius: 10px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    &-divider {
      margin: 8px 0px !important;
    }
    &-text {
      font-size: 14px !important;
      color: #6c757d !important;
    }
    &-link {
      color: #7e62d1 !important;
      text-decoration: underline;
    }
  }

  .financing-footer {
    text-align: center;
    padding: 80px 0;
    background-color: #fff;
    .ant-btn-primary.financing-button {
      background-color: #7e62d1 !important;
      border: none;
      border-radius: 7px;
      color: #fff;
      font-size: 16px;
      height: 44px;
      &:hover,
      &:focus,
      &:active {
        background-color: #7e62d1 !important;
        border: none !important;
      }
    }
    &-border {
      border: 1px solid #f0f0f0;
      border-radius: 100px;
      padding: 16px 20px;
      margin: 0 12px;
      &-info {
        margin-bottom: 0;
      }
    }
  }
}
.term_condition {
  .ant-btn.ant-btn-primary {
    border-color: unset;
    background-color: #7e62d1;
  }
  .ant-btn.ant-btn-default:hover,
  .ant-btn.ant-btn-default:focus {
    color: black;
    border-color: black;
    background-color: white;
  }
}
