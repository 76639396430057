.tab_container {
  .ant-tabs-nav-wrap {
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #8c8c8c;
      border-radius: 10px;
    }
    .ant-tabs-nav-list {
      margin-bottom: 5px;
    }
    cursor: all-scroll;
  }
}
