.page-header {
  margin: 0px 0px 32px 0px;
  display: flex;
  align-items: center;
    
  .page_title {
    font-size: 22px;
    padding: 10px 0;

    @media screen and (min-width: 768px) {
      font-size: 28px;
    }
  }

  .button_container {
    margin-left: auto;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 5px 5px;
      align-items: flex-start;
      margin-left: 0px;
      margin-bottom: 24px;
    }
    @media screen and (max-width: 768px) {
      // button:first-child {
      //   margin-top: 16px;
      // }
    }
    button {
      margin-left: 6px;
      background-color: black;
      border-color: black;
      height: 40px;

      @media screen and (max-width: 768px) {
        margin-left: 0px !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
